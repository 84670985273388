<template>
  <div>
    <v-container v-if="!!train" fluid style="height: calc(100vh - 88px)" class="pa-0 ma-0">
      <!-- {{ train }} -->
      <v-row class="fill-height pa-0 ma-0">
        <v-col cols="3">
          <v-sheet color="grey lighten-3" class="fill-height">
            <v-container fluid fill-height class="pa-0 ma-0">
              <v-row class="fill-height pa-0 ma-0">
                <v-col cols="12" align-self="start" class="pa-0 ma-0">
                  <div :class="voyageBarColor" style="height: 106px">
                    <v-container fluid fill-height>
                      <v-row align="center">
                        <v-col cols="2">
                          <v-icon x-large color="white" @click="goToGate">mdi-chevron-left</v-icon>
                        </v-col>
                        <v-col cols="10" class="white--text text-h4 text-right font-weight-bold">
                          {{ voyage }}
                        </v-col>
                      </v-row>
                      <v-row align="center" v-if="!railRegOK">
                        <v-col cols="12" class="d-flex justify-end">
                          <v-btn fab color="secondary" @click="openMatchDialog()" 
                            :title="tLabel((!!train && train.tosVoyage) ? 'Cambia viaggio' : 'Associa viaggio')">
                            <v-icon large>{{ (!!train && !!train.tosVoyage) ? "mdi-swap-horizontal" : "mdi-link" }}</v-icon>
                          </v-btn>
                          &nbsp;
                          <v-btn fab color="secondary" v-if="(!!train && !!train.tosVoyage)" @click="showConfirmUnlinkTosVoyageDialog = true" 
                            :title="tLabel('Dissocia viaggio')">
                            <v-icon large>mdi-link-off</v-icon>
                          </v-btn>
                          {{(!!train && !!train.tosVoyage) && hasPassage ? '&nbsp;' : ''}}
                          <!-- && !train.rrDate -->
                          <v-btn fab color="secondary" v-if="(!!train && !!train.tosVoyage) && hasPassage" @click="showConfirmSendToTosDialog = true" 
                            :title="tLabel('Invio viaggio al TOS')">
                            <v-icon>mdi-send</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
                <v-col cols="12" align-self="end" class="pb-0 mb-0">
                  <div style="height: 300px;">                   
                    <v-container fluid fill-height>
                      <v-row align="center">
                        <v-col cols="12" class="my-0 py-0">
                          <span class="text-overline text--left my-0 py-0">Messaggi</span>
                          <div class="message-box">
                              {{ !!train.rrMessages ? train.rrMessages : ""}}
                          </div>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col cols="10" class="pb-1 mb-1">
                          <v-text-field hide-details="true" label="Num. carro/UTI" v-model="carroUtiSearchFieldValue" />
                        </v-col> 
                        <v-col cols="2" class="pb-1 mb-1">
                          <v-btn small outlined fab right color="primary" @click="searchUtiCarro" elevation="3" :title="tLabel('Cerca per carro/UTI')">
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </v-col>                        
                      </v-row>
                    </v-container>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-col>
        <v-col cols="9" class="pa-0 ma-0">
          <v-container fluid fill-height class="pa-0 ma-0">
            <v-row style="height: 130px" class="fill-height pa-0 ma-0">
              <v-col cols="12">
                <v-sheet color="grey lighten-3" class="fill-height">
                  <v-container fluid fill-height>
                    <v-row>
                      <v-col v-if="!!origin || !!destination" cols="6" class="d-flex justify-start">
                        <span class="text-h4 text-left font-weight-light pr-2">{{ origin }}</span>
                        <v-icon x-large color="grey">mdi-ray-start-end</v-icon>
                        <span class="text-h4 text-left font-weight-light pl-2">{{ destination }}</span>
                      </v-col>
                      <v-col cols="6" class="text-h4 text-right font-weight-light d-flex justify-end">
                        {{ railwayCompany }}
                      </v-col>
                    </v-row>
                  </v-container>
                </v-sheet>
              </v-col>
            </v-row>
            <v-row class="pa-0 ma-0" style="height: calc(100vh - 218px)">
              <v-col cols="6">
                <TrainCompositionSheet
                  :trainComposition="train.preadvice || null"
                  trainCompositionType="PREADVICE"
                  :source="train.source"
                  :wagonCountDiscrepancy="wagonCountDiscrepancy"
                  :utiCountDiscrepancy="utiCountDiscrepancy"
                />
              </v-col>
              <v-col cols="6">
                <TrainCompositionSheet
                  :trainComposition="train.passage || null"
                  trainCompositionType="PASSAGE"
                  :rrStatus="train.rrStatus"
                  :wagonCountDiscrepancy="wagonCountDiscrepancy"
                  :utiCountDiscrepancy="utiCountDiscrepancy"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog max-width="60%" v-model="showViaggiTosDialog">
      <v-card v-if="showViaggiTosDialog">
        <v-card-title class="text-uppercase font-weight-regular py-2 panelheader">
          <v-icon class="pr-2">{{ (!!train && !!train.tosVoyage) ? "mdi-swap-horizontal" : "mdi-link" }}</v-icon>
          {{ (!!train && !!train.tosVoyage) ? tLabel("Cambia viaggio") : tLabel("Associa viaggio") }}
        </v-card-title>
        <v-card-text>
          <v-row align="center">
            <v-col cols="4">
              <v-text-field label="Viaggio" v-model="tosVoyageCodeFilter" clearable :disabled="!showViaggiTosList"></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-btn small outlined fab right color="primary" @click="getViaggiTos" elevation="3" :disabled="!showViaggiTosList">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12">
              <v-simple-table height="400">
                <template v-slot:default>
                  <tbody v-if="!showViaggiTosList">
                    <tr>
                      <td colspan="5" class="grey--text text-h5 font-weight-bold text-uppercase">{{ tLabel("Caricamento viaggi TOS..") }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else-if="!!tosVoyages && tosVoyages.length > 0" style="overflow-y: auto;">
                    <tr
                      v-for="tv in tosVoyages"
                      :key="tv.uid"
                      @click="tosVoyageSelected = tv"
                      :class="!!tosVoyageSelected && tosVoyageSelected.uid === tv.uid ? 'teal lighten-5 pointer' : 'pointer'"
                    >
                      <td style="width: 10%;" class="grey--text text-h5 font-weight-bold">{{ tv.voyage }}</td>
                      <td style="width: 20%; vertical-align: middle" class="text-subtitle-1 font-weight-light">Da: {{ tv.source }}</td>
                      <td style="width: 20%;" class="text-subtitle-1 font-weight-light">ETA: {{ tv.eta }}</td>
                      <td style="width: 10%;" class="text-subtitle-1 font-weight-light">Carri: {{ tv.nwagons }}</td>
                      <td style="width: 10%;" class="text-subtitle-1 font-weight-light">UTI: {{ tv.nuti }}</td>
                      <td class="text-subtitle-1 font-weight-light">
                        <v-progress-linear
                          v-model="tv.matchPercent"
                          :color="tv.matchPercent<tv.thresholdPercent?'warning':'success'"
                          height="22"   
                          class="my-2 progress-bar"                      
                        >
                          <template v-slot:default="{ value }">
                            <strong>{{ Math.ceil(value) }}%</strong>
                          </template>
                        </v-progress-linear>
                        <v-container class="ma-0 pa-0">
                          <v-row align="center">
                            <v-col cols="4" class="text-center" :title="tLabel('Numero di UTI al gate presenti sul viaggio TOS')">
                               {{ tv.nutiMatched }} <v-icon small color="success">mdi-check</v-icon>
                            </v-col>
                            <v-col cols="4" class="text-center" :title="tLabel('Numero di UTI al gate non presenti sul viaggio TOS')">
                              {{ tv.nutiNotFounds }} <v-icon small color="error">mdi-close</v-icon> 
                            </v-col>
                            <v-col cols="4" class="text-center" :title="tLabel('Numero di UTI presenti sul viaggio TOS ma non presenti al gate')">
                               {{ tv.nutiExtra }} <v-icon small color="secondary">mdi-plus</v-icon>
                            </v-col>
                          </v-row>
                        </v-container>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="grey--text text-h5 font-weight-bold text-uppercase">{{ tLabel("No voyages.") }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showViaggiTosDialog = false" outlined :disabled="!showViaggiTosList">{{ tLabel("Cancel") }}</v-btn>
          <v-btn @click="showConfirmLinkTosVoyageDialog = true" color="success" :disabled="!tosVoyageSelected">{{ tLabel("Associa") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="progress.model" fullscreen transition="false">
      <v-row align="center" justify="center" class="fill-height">
        <v-col cols="12" class="text-center">
          <v-progress-circular indeterminate :size="70" :width="7" color="grey"></v-progress-circular>
        </v-col>
      </v-row>
    </v-dialog>

    <v-dialog max-width="600px" v-model="showConfirmLinkTosVoyageDialog">
      <v-card v-if="showConfirmLinkTosVoyageDialog">
        <v-card-title class="text-uppercase font-weight-regular py-2 panelheader">
          <v-icon class="pr-2">mdi-check</v-icon>
          {{ tLabel("Conferma") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" class="text-h6 font-weight-light" no-gutters>
              <v-col cols="12"
                >Associare <b>{{ voyage }}</b> con <b>{{ tosVoyageSelected.voyage }}</b
                >?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmLinkTosVoyageDialog = false" outlined>{{ tLabel("No") }}</v-btn>
          <v-btn
            @click="
              showConfirmLinkTosVoyageDialog = false;
              associaViaggio();
            "
            color="success"
            >{{ tLabel("Si") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="showConfirmUnlinkTosVoyageDialog">
      <v-card v-if="showConfirmUnlinkTosVoyageDialog">
        <v-card-title class="text-uppercase font-weight-regular py-2 panelheader">
          <v-icon class="pr-2">mdi-check</v-icon>
          {{ tLabel("Conferma") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" class="text-h6 font-weight-light" no-gutters>
              <v-col cols="12"
                >Dissociare <b>{{ voyage }}</b>?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmUnlinkTosVoyageDialog = false" outlined>{{ tLabel("No") }}</v-btn>
          <v-btn
            @click="
              showConfirmUnlinkTosVoyageDialog = false;
              dissociaViaggio();
            "
            color="success"
            >{{ tLabel("Si") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600px" v-model="showConfirmSendToTosDialog">
      <v-card v-if="showConfirmSendToTosDialog">
        <v-card-title class="text-uppercase font-weight-regular py-2 panelheader">
          <v-icon class="pr-2">mdi-check</v-icon>
          {{ tLabel("Conferma") }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row align="center" class="text-h6 font-weight-light" no-gutters>
              <v-col cols="12">
                Inviare passaggio del viaggio <b>{{ voyage }}</b> al TOS?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showConfirmSendToTosDialog = false" outlined>{{ tLabel("No") }}</v-btn>
          <v-btn
            @click="
              showConfirmSendToTosDialog = false;
              inviaViaggioAlTos();
            "
            color="success"
            >{{ tLabel("Si") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TrainCompositionSheet from "../../../components/rails/gate/TrainCompositionSheet";
import DateTimeAdapter from "@/components/anagrafiche/DateTimeAdapter";

export default {
  name: "TrainDetail",
  data() {
    return {
      train: null,
      wagonCountDiscrepancy: false,
      utiCountDiscrepancy: false,
      showViaggiTosDialog: false,
      showViaggiTosList: false,
      showConfirmLinkTosVoyageDialog: false,
      showConfirmUnlinkTosVoyageDialog: false,
      showConfirmSendToTosDialog: false,
      tosVoyages: null,
      tosVoyageSelected: null,
      tosVoyageCodeFilter: null,
      carroUtiSearchFieldValue: null,
      interval: null,
      progress: {
        model: false,
      },
    };
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      default: null,
    },
  },
  components: { TrainCompositionSheet, DateTimeAdapter },
  async created() {
    this.interval = setInterval(async () => {
      try {
        this.train = await this.getTrain();
      } catch (e) {
        console.log(e);
      }
    }, 30000);
  },
  async mounted() {
    this.train = await this.getTrain();
    console.log("TrainDetail train", this.train);
  },
  destroyed() {
    clearInterval(this.interval);
  },
  computed: {
    voyage() {
      if (this.train) {
        if (this.train.tosVoyage) {
          return this.train.tosVoyage;
        } else if (this.hasPassage) {
          return this.train.passage.voyage;
        } else if (this.hasPreadvice) {
          return this.train.preadvice.voyage;
        }
      }
      return null;
    },
    voyageBarColor() {
      return (!!this.train && !!this.train.tosVoyage) ? "primary" : "warning";
    },
    origin() {
      if (this.train) {
        if (this.hasPassage && !!this.train?.passage?.origin) {
          return this.train.passage.origin;
        } else if (this.hasPreadvice && !!this.train?.preadvice?.origin) {
          return this.train.preadvice.origin;
        }
      }
      return null;
    },
    destination() {
      if (this.train) {
        if (this.hasPassage && !!this.train?.passage?.destination) {
          return this.train.passage.destination;
        } else if (this.hasPreadvice && !!this.train?.preadvice?.destination) {
          return this.train.preadvice.destination;
        }
      }
      return null;
    },
    railwayCompany() {
      if (this.train) {
        if (this.hasPassage && !!this.train?.passage?.railwayCompany) {
          return this.train.passage.railwayCompany;
        } else if (this.hasPreadvice && this.train?.preadvice?.railwayCompany) {
          return this.train.preadvice.railwayCompany;
        }
      }
      return null;
    },
    hasPreadvice() {
      return !!this.train?.preadvice?.voyage;
    },
    hasPassage() {
      return !!this.train?.passage?.voyage;
    },
    railRegOK(){
      return this.train.rrStatus=='OK';
    }
  },
  methods: {
    searchUtiCarro() {
      this.$eventBus.$emit("searchUtiCarro", this.carroUtiSearchFieldValue);
    },
    goToGate() {
      this.$router.push({
        name: "railGateFrom",
        params: {
          filters: this.filters,
        },
      });
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    openMatchDialog() {
      this.prepareTosVoyageSelection()
      this.showViaggiTosDialog = true;
    },
    async prepareTosVoyageSelection() {
      try {
        this.progress.model = true;
        this.showViaggiTosList = false;

        this.tosVoyageCodeFilter = null;
        this.tosVoyageSelected = null;
        this.tosVoyages = null;
        
        // await this.sleep(2000); PER TEST
        await this.getViaggiTos();
        this.progress.model = false;
        this.showViaggiTosList = true;
      } catch (e) {
        this.progress.model = false;
        this.showViaggiTosList = false;
      } 
    },
    async getViaggiTos() {
      let tosVoyagesTmp;
      tosVoyagesTmp = await this.$api.railGateData.getTosVoyagesByUid(this.train.uid);

      if (tosVoyagesTmp && tosVoyagesTmp.length>0) {
        if (this.tosVoyageCodeFilter) {
          tosVoyagesTmp = tosVoyagesTmp.filter((v) => v.voyage?.toUpperCase().startsWith(this.tosVoyageCodeFilter.toUpperCase()));
        }
        tosVoyagesTmp = tosVoyagesTmp.sort((a, b) => (a.matchPercent > b.matchPercent ? 1 : -1));
      }     
      this.tosVoyages = tosVoyagesTmp;
      console.log("getViaggiTos | tosVoyages", this.tosVoyages);
    },
    async associaViaggio() {
      try {
        let res = await this.$api.railGateData.manualMatch(this.train.uid, this.tosVoyageSelected.uid, this.tosVoyageSelected.voyage);
        console.log("associaViaggio res", res);
        if (res && res === "OK") {
          this.train = await this.getTrain();
          this.showViaggiTosDialog = false;
        }
      } catch (e) {
        console.error(e);
        this.showError("Errore associazione viaggio! " + e);
      }
    },
    async dissociaViaggio() {
      try {
        let res = await this.$api.railGateData.clearMatch(this.train.uid);
        console.log("dissociaViaggio res", res);
        if (res && res === "OK") {
          this.train = await this.getTrain();
        }
      } catch (e) {
        console.error(e);
        this.showError("Errore dissociazione viaggio! " + e);
      }
    },
    async inviaViaggioAlTos() {
      try {
        await this.$api.railGateData.sendRailRegistration(this.train.uid);
        //console.log("inviaViaggioAlTos res", res);
        this.train = await this.getTrain();      
      } catch (e) {
        console.error(e);
        this.showError("Errore invio viaggio al TOS! " + e);
      }
    },
    async getTrain() {
      let trainTmp = await this.$api.railGateData.getVoyageByUid(this.uid);

      let preadviceWagonCodes = [];
      let preadviceUtiCodes = [];
      let preadviceWagonCount = null;
      let preadviceUtiCount = null;

      let passageWagonCodes = [];
      let passageUtiCodes = [];
      let passageWagonCount = null;
      let passageUtiCount = null;

      if (trainTmp) {
        if (trainTmp?.preadvice?.wagons) {
          trainTmp.preadvice.wagons.forEach((wagon) => {
            if (preadviceWagonCount === null) {
              preadviceWagonCount = 0;
            }
            preadviceWagonCount++;
            preadviceWagonCodes.push(wagon.code);
            if (wagon.units) {
              wagon.units.forEach((uti) => {
                if (preadviceUtiCount === null) {
                  preadviceUtiCount = 0;
                }
                preadviceUtiCount++;
                preadviceUtiCodes.push(uti);
              });
            }
          });
        }
        if (trainTmp?.passage?.wagons) {
          trainTmp.passage.wagons.forEach((wagon) => {
            if (passageWagonCount === null) {
              passageWagonCount = 0;
            }
            passageWagonCount++;
            passageWagonCodes.push(wagon.code);
            if (wagon.units) {
              wagon.units.forEach((uti) => {
                if (passageUtiCount === null) {
                  passageUtiCount = 0;
                }
                passageUtiCount++;
                passageUtiCodes.push(uti);
              });
            }
          });
        }

        // console.log("+++++ preadviceWagonCount", preadviceWagonCount);
        // console.log("+++++ preadviceUtiCount", preadviceUtiCount);
        // console.log("+++++ passageWagonCount", passageWagonCount);
        // console.log("+++++ passageUtiCount", passageUtiCount);
        this.wagonCountDiscrepancy = preadviceWagonCount && passageWagonCount && preadviceWagonCount !== passageWagonCount;
        this.utiCountDiscrepancy = preadviceUtiCount && passageUtiCount && preadviceUtiCount !== passageUtiCount;
        // console.log("+++++ wagon/uti count discrepancy", this.wagonCountDiscrepancy, this.utiCountDiscrepancy);

        if (trainTmp?.preadvice?.wagons) {
          trainTmp.preadvice.wagons.forEach((wagon) => {
            if (passageWagonCodes.length > 0 && !passageWagonCodes.includes(wagon.code)) {
              wagon.discrepancy = true;
            }
            if (wagon.units) {
              let unitsObj = wagon.units.map((uti) => {
                return {
                  code: uti,
                  discrepancy: passageUtiCodes.length > 0 && !passageUtiCodes.includes(uti)
                };
              });
              wagon.units = JSON.parse(JSON.stringify(unitsObj));
            }
          });
        }

        if (!!trainTmp?.passage?.wagons) {
          trainTmp.passage.wagons.forEach((wagon) => {
            if (preadviceWagonCodes.length > 0 && !preadviceWagonCodes.includes(wagon.code)) {
              wagon.discrepancy = true;
            }
            if (!!wagon?.units) {
              let unitsObj = wagon.units.map((uti) => {
                return {
                  code: uti,
                  discrepancy: preadviceUtiCodes.length > 0 && !preadviceUtiCodes.includes(uti)
                };
              });
              wagon.units = JSON.parse(JSON.stringify(unitsObj));
            }
          });
        }
      }
      return trainTmp;
    },
  },
};
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}
.pointer {
  cursor: pointer;
}
.message-box {
  overflow-y: scroll; 
  background-color: white; 
  height: 160px;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.1)
}
.progress-bar {
  pointer-events: none;
}
</style>
